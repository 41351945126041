.not-found {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  min-height: 100vh;

  &__inner {
    width: 100%;
    max-width: 344px;
    margin: 74px 20px;
    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__text {
    margin-top: 40px;
    font-size: 14px;
    line-height: 18px;
    color: #6a6a7a;
  }

  &__link {
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #3748c6;
  }
}

.page_auth {
  min-height: 100vh;
  background-color: #ffffff;
  .header__navigation {
    display: none;
  }

  .header__link {
    display: none;
  }

  .menu-button {
    display: none;
  }
}

.text-field {
  display: block;
  line-height: 1;

  &__field-group {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: var(--gray-30);
    border-radius: 6px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }

  &__field {
    outline: none;
    border: none;
    flex-grow: 1;
    font-size: 14px;
    line-height: 16px;
    min-width: 0;
    font-family: inherit;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #a8a8b7;
      font-family: inherit;
    }
  }

  &_size {
    &_large {
      .text-field__field-group {
        height: 48px;
      }
    }

    &_medium {
      .text-field__field-group {
        height: 40px;
      }
    }
  }

  &_type {
    &_primary {
      &:not(.text-field_disabled) {
        &.text-field_focused {
          .text-field__field-group {
            border-color: var(--blue-70);
            box-shadow: 0 0 0 3px var(--blue-00);
          }
        }
      }
    }
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: inline-block;
  }

  &__error {
    font-size: 12px;
    line-height: 14px;
    color: #ed6f78;
    margin-top: 8px;
  }

  &_multiline {
    .text-field {
      &__field-group {
        height: auto;
      }

      &__field {
        padding-top: 12px;
        padding-bottom: 12px;
        resize: none;
      }
    }
  }
}

:root {
  /* COLORS */
  --white: #ffffff;
  --black: #000000;

  --black-20: #010013;
  --black-10: #040134;
  --black-00: #04014c;

  --blue-100: #1f2d99;
  --blue-90: #2434a9;
  --blue-80: #2c3db9;
  --blue-70: #3748c6;
  --blue-60: #4455d1;
  --blue-50: #5666de;
  --blue-40: #6e7ce7;
  --blue-30: #8b97ef;
  --blue-20: #aeb6f5;
  --blue-10: #d5d9fa;
  --blue-00: #eaecfc;

  --gray-70: #6a6a7a;
  --gray-60: #898999;
  --gray-50: #a8a8b7;
  --gray-40: #c4c4cf;
  --gray-30: #d9d9e1;
  --gray-20: #e9e9ee;
  --gray-10: #f4f4f7;
  --gray-00: #f7f7fa;

  --text-primary: var(--black-10);
  --text-secondary: var(--blue-70);
  --text-quaternary: var(--gray-70);
  --text-quinary: var(--gray-50);

  --background-primary: var(--white);
  --background-secondary: var(--gray-10);

  --divider: var(--gray-20);

  /* SIZES */
  --header-height: 73px;
}

/* MEDIA */
$notebook: 1279px;
$tablet: 1023px;
$phone: 767px;

@media (max-width: $tablet) {
  :root {
    --header-height: 73px;
  }
}

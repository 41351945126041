.Block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  position: relative;
  width: 100%;
  height: 520px;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 32px;
  transition: all 0.3s cubic-bezier(0, 0, 0.4, 1);
}

.Block:hover {
  transform: scale(1.02);
}

.Block.Small {
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;

  height: 600px;
}

.BlockBgGreen {
  background: radial-gradient(
      100% 100% at 0% 0%,
      rgba(2, 82, 102, 0) 46.64%,
      rgba(2, 82, 102, 0.24) 100%
    ),
    radial-gradient(
      100% 100% at 0% 0%,
      rgba(12, 157, 70, 0.4) 18.85%,
      rgba(12, 157, 70, 0) 72.93%
    ),
    radial-gradient(
      100% 100% at 0% 100%,
      rgba(45, 224, 111, 0.4) 18.65%,
      rgba(45, 224, 111, 0) 46.48%
    ),
    radial-gradient(
      100% 77.93% at 100% 20.52%,
      rgba(112, 66, 13, 0.28) 0%,
      rgba(112, 66, 13, 0) 62.27%
    ),
    linear-gradient(0deg, #a0eb09, #a0eb09);
}

.BlockBgBlack {
  background-color: #111114;
}

.BlockBgWhite {
  background-color: #ffffff;
}

.BlockBgOrange {
  background: radial-gradient(
      100% 200% at 0% 100%,
      #ffd970 19.04%,
      rgba(255, 225, 144, 0) 100%
    ),
    radial-gradient(100% 400% at 0% 0%, #ffda47 0%, #ffce0a 66.85%);
}

.BlockBgBlue {
  background: radial-gradient(
      100% 400% at 0% 100%,
      rgba(4, 107, 224, 0) 46.94%,
      rgba(14, 22, 138, 0.16) 90.53%
    ),
    radial-gradient(
      138.17% 138.17% at 100% 100%,
      #3e3ec3 3.01%,
      #0e99fd 64.06%,
      #1abefa 100%
    );
}

.BlockBgViolet {
  background: radial-gradient(
    137.57% 136.73% at 100% 100%,
    #8b7ff5 0%,
    #0e168a 100%
  );
}

.BlockBgBlueViolet {
  background: radial-gradient(
      166.46% 105.39% at 42.02% -0%,
      rgba(4, 107, 224, 0) 46.94%,
      rgba(14, 22, 138, 0.16) 90.53%
    ),
    radial-gradient(138.17% 138.17% at 100% 100%, #7b61ff 3.01%, #1f67ef 72.25%);
}

.BlockLeftWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-height: 440px;
}

.Block.Small .BlockLeftWrap {
  padding: 40px 40px 0;
  margin-bottom: 60px;
}

.BlockInfo {
}

.BlockTitle {
  margin: 0 0 16px;
  color: #ffffff;
  font-size: 34px;
  text-align: left;
  line-height: 42px;
  letter-spacing: -0.24px;
  font-weight: 600;
}

.BlockDesc {
  color: #ffffff;
}

.BlockBgWhite .BlockTitle,
.BlockBgOrange .BlockTitle {
  color: #000000;
}

.BlockBgWhite .BlockDesc {
  color: #58585c;
}

.BlockBgGreen .BlockDesc {
  color: #ebffc2;
}

.BlockBgBlack .BlockDesc {
  color: #cacacc;
}

.BlockBgOrange .BlockDesc {
  color: #70420d;
}

.BlockBgBlue .BlockDesc {
  color: #d6ebff;
}

.BlockBgViolet .BlockDesc {
  color: #d0cbfe;
}

.BlockBgBlueViolet .BlockDesc {
  color: #d6ebff;
}

.BlockAction {
  position: relative;
  z-index: 4;
  margin-top: 20px;
}

.Block.Small .BlockAction {
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.Block.Small .BlockDesc {
  margin-bottom: -20px;
}

.BlockAction.Margins {
  position: absolute;
  bottom: 40px;
  left: 28px;
}

.BlockRightWrap {
  display: flex;
  justify-content: flex-end;
  position: relative;

  max-height: 440px;
}

.BlockRightWrap.Padding {
  padding: 40px;
}

.BlockRightWrap.NoImageBottomPadding {
  padding-bottom: 0;
}

.Block.Small .BlockRightWrap.Padding {
  padding-top: 0;
}

.BlockRightImg {
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.ActiveBtn {
  background-color: #ffffff;
  color: #000000;
}

.Block.Small .BlockRightImg {
  height: auto;
}

.Subtitle {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: -0.24px;
  text-align: left;
  font-weight: 600;
  font-style: italic;
  background: #353538;
  border-radius: 8px;
  max-width: 99px;
  color: #ffffff;
  margin-bottom: 16px;
}

.ActionListWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
}

.FullImg {
  height: auto !important;
}

.NoPadding .BlockRightWrap {
  max-height: none;
}

.NoPadding {
  padding: 0;
}

.WithPadding {
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
  box-sizing: initial;
}

@media (max-width: 1080px) {
  .WithPadding {
    padding-right: 40px;
  }
}

@media (max-width: 940px) {
  .Block.Small {
    height: 520px;
  }

  .BlockRightWrap {
    align-items: flex-end;
  }

  .BlockTitle {
    font-size: 28px;
    line-height: 30px;
  }

  .BlockDesc {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (max-width: 743px) {
  .Block {
    grid-template-columns: 1fr;
    height: auto;

    &:not(.NoPadding, .Small) {
      padding: 28px;
    }
  }

  .BlockTitle {
    font-size: 24px;
    letter-spacing: -0.16px;
    line-height: 28px;
  }

  .BlockDesc {
    letter-spacing: -0.12px;
  }

  .Block.Small {
    height: auto;
  }

  .Block.Small .BlockLeftWrap {
    padding: 28px 28px 0;
    margin-bottom: 30px;
  }

  .Block:not(.Small):is(.NoPadding) .BlockAction {
    padding: 0 0 28px 28px;
  }

  .BlockRightWrap {
    justify-content: center;

    max-height: none;
  }

  .BlockRightWrap.Padding {
    padding: 28px;
  }

  .BlockRightWrap.NoImageBottomPadding {
    padding-bottom: 0;
  }

  .Subtitle {
    font-size: 24px;
    line-height: 42px;
    letter-spacing: -0.24 px;
    max-width: 75px;
    padding: 0 3px;
  }

  .BlockAction {
    margin-top: 0;
  }

  .Block.Small .BlockAction {
    left: 28px;
    bottom: 28px;
  }
}

@media (max-width: 584px) {
  .WithPadding {
    padding: 28px;
  }
}

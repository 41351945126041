.chat-locker {
  display: none;
  width: 140px;
  height: 140px;
  z-index: 1000000;
  position: fixed;
  right: 0;
  bottom: 0;
}

.locked {
  display: block;
}

.page {
  height: inherit;
  display: grid;
  grid-template-rows: 1fr auto;
  padding-top: var(--header-height);
  box-sizing: border-box;

  &__container {
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }

  &__section {
    padding-top: 136px;
    padding-bottom: 136px;
    box-sizing: border-box;
  }

  &__language-selector-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
}

@media (max-width: $phone) {
  .page {
    &__section {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    &__container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.form-footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #a8a8b7;
  margin-top: 20px;

  p {
    margin-top: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: #3748c6;
  }
}

.Footer {
  width: 100%;
  padding: 0px 0 160px;
  background-color: #000000;

  @media (max-width: 743px) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.LinkFooter {
  color: #0582ff;
}

.InnerWrapperFooter {
  margin-top: 160px;
}

.WrapperSocial {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: -40px;

  @media (max-width: 743px) {
    margin-top: 0;
  }
}

.WrapperText {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #ffffff;
}

.OnlyMobile {
  display: none;
}

@media (max-width: 1023px) {
  .WrapperText {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.16px;
  }
}

@media (max-width: 560px) {
  .WrapperText {
    font-size: 24px;
  }

  .OnlyMobile {
    display: block;
  }

  .WrapperButtons {
    flex-direction: column;
  }

  .InnerWrapperFooter {
    margin-top: 144px;
  }
}

@media (max-width: 366px) {
  .WrapperText {
    font-size: 20px;
    line-height: 24px;
  }
}

.Hero {
  padding: 224px 0 122px;
  margin-bottom: 128px;
  position: relative;
}

.DecorImg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.BlockTitle {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.16px;
  text-align: center;
  color: #78787a;
}

.Title {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 80px;
  font-weight: 600;
  line-height: 88px;
  letter-spacing: -0.96px;
  text-align: center;
}

.Description {
  margin: 0 auto 56px auto;

  text-align: center;
  max-width: 595px;
}

.Action {
  display: flex;
  justify-content: center;
}

@media (max-width: 743px) {
  .Title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.24px;
  }

  .BlockTitle {
    font-size: 17px;
    letter-spacing: 0.08px;
    line-height: 22px;
  }

  .Description {
    font-size: 17px;
    letter-spacing: -0.12px;
    line-height: 24px;
  }
}

@media (max-width: 743px) {
  .Hero {
    padding: 224px 0 0;
    margin-bottom: 144px;
  }

  .DecorImg {
    top: 60px;
  }

  .Action {
    justify-content: normal;

    & > a {
      width: 100%;
    }
  }
}

.notify {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 100%;
    max-width: 344px;
    margin: 64px 20px;
    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__image {
    margin-bottom: 40px;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    color: #6a6a7a;
    white-space: pre-line;
  }

  &__link {
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #3748c6;
  }
}

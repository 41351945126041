.BlockHeader {
  margin: 200px 0 96px;
}

.BlockHeader.NoTopMargin {
  margin-top: 0;
}

.BlockTitle {
  margin: 0 0 20px;
  color: #78787A;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.WhiteColorText {
  color: #FFFFFF
}

.Title {
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.56px;
  text-align: center;
}

@media (max-width: 743px) {
  .BlockHeader {
    margin: 120px 0 60px;
  }

  .BlockTitle {
    font-size: 17px;
    letter-spacing: .08px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .Title {
    font-size: 28px;
    letter-spacing: -.16px;
    line-height: 32px;
  }
}

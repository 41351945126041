.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;

  &_level {
    &_hero {
      font-size: 48px;
      line-height: 64px;
    }

    &_2 {
      font-size: 40px;
      line-height: 56px;
    }

    &_3 {
      font-size: 24px;
      line-height: 32px;
    }

    &_4 {
      font-size: 20px;
      line-height: 28px;
    }

    &_5 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (max-width: $notebook) {
}

@media (max-width: $tablet) {
  .title {
    &_level {
      &_2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: $phone) {
  .title {
    &_level {
      &_hero {
        font-size: 32px;
        line-height: 40px;
      }

      &_2 {
        font-size: 24px;
        line-height: 32px;
      }

      &_3 {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

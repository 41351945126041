.sign-up-form {
  display: flex;
  align-items: center;

  &_duration {
    &_row {
      flex-direction: row;

      .sign-up-form__text-field {
        margin-right: 12px;
      }
    }

    &_column {
      flex-direction: column;

      .sign-up-form__text-field {
        margin-bottom: 20px;
        width: 100%;
      }

      .sign-up-form__button {
        width: 100%;
      }
    }
  }
}

.wrapper {
  margin-top: 78px;
  min-height: calc(100vh - 78px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 500px;
  margin: 0 auto 0 auto;
  padding: 30px;
}

.radioGroup {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: -15px;

  label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.formTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.fieldWithButton {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  > :first-child {
    flex-grow: 1;
  }
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #046be0;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 10000;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.error {
  background-color: #ff4d4d;
}

.wrapper {
  position: fixed;
  padding: 24px;
  box-sizing: border-box;
  max-width: 344px;
  background-color: #111114;
  border-radius: 24px;
  bottom: 24px;
  left: 24px;
  font-family: Inter;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #353538;
  z-index: 10000;
}

.title {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.link {
  color: #afafb3;
}

.description {
  color: #828285;

  &:nth-child(2) {
    margin-top: 12px;
  }

  &:nth-child(3) {
    margin-top: 8px;
  }
}

.button {
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  padding: 10px 20px;
  color: #ffffff;
  text-align: center;
  background: #2b2b2e;
  border: 1px solid #434347;
  border-radius: 24px;

  &:hover {
    border: 1px solid #58585c;
    background: #353538;
  }
}

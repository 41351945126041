.cabinet-select {
  margin-bottom: 20px;

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #f4f4f7;
    border-color: #d9d9e1;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    box-sizing: border-box;
    margin-right: 16px;
    font-size: 14px;
    line-height: 16px;
    flex-grow: 1;
    transition: background-color 0.2s ease;
    text-decoration: none;
    color: #040134;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: #e9e9ee;
    }

    &_active {
      color: #3748c6;
      border-color: #3748c6;
      background-color: #eaecfc;

      &:hover {
        background-color: #d5d9fa;
      }
    }
  }

  &__radio {
    display: none;
  }
}

.Block {
  display: grid;
  grid-template-columns: 1fr 500px;
  gap: 16px;
  position: relative;
  width: 100%;
  height: 520px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 32px;
  transition: all 0.3s cubic-bezier(0, 0, 0.4, 1);
}

.Block.BlockBlack {
  background: #111114;
}

.Block.BlockGreen {
  background: radial-gradient(
      91.57% 117.19% at 2.02% 0%,
      rgba(2, 82, 102, 0) 53.7%,
      rgba(2, 82, 102, 0.24) 100%
    ),
    radial-gradient(
      131.46% 134.91% at -0% 100%,
      rgba(45, 224, 111, 0.4) 18.65%,
      rgba(45, 224, 111, 0) 46.48%
    ),
    radial-gradient(
      134.55% 136.62% at 0% -0%,
      rgba(12, 157, 70, 0.4) 18.85%,
      rgba(12, 157, 70, 0) 72.93%
    ),
    radial-gradient(203.95% 199.08% at 76.68% 47.79%, #bf0 0%, #049fb7 100%);
}

.Block:hover {
  transform: scale(1.02);
}

.BlockLeftWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
}

.BlockTitle {
  margin: 0 0 16px;
  color: #ffffff;
  font-size: 34px;
  text-align: left;
  line-height: 42px;
  letter-spacing: -0.24px;
  font-weight: 600;
}

.BlockDesc {
  color: #ffffff;
}

.BlockBlack .BlockDesc {
  color: #cacacc;
}

.BlockGreen .BlockDesc {
  color: #ebffc2;
}

.BlockAction {
  margin-top: 20px;
}

.BlockRightWrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
}

.ToggleBtnsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 4px;
  background: #434347;
  border-radius: 48px;
  color: #0582ff;
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 120px;
  height: 48px;
  z-index: 10;
}

.WrapperRightDesktop {
  padding: 40px 0 40px 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.DesktopImage {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding-left: 7px;
}

@media (max-width: 1073px) {
  .DesktopImage {
    max-height: 425px;
    max-width: 515px;
    margin-left: auto;
  }
}

@media (max-width: 562px) {
  .DesktopImage {
    max-height: 350px;
    max-width: 360px;
  }
}

@media (max-width: 400px) {
  .DesktopImage {
    max-height: 331px;
    max-width: 298px;
  }
}

@media (max-width: 340px) {
  .DesktopImage {
    max-width: 265px;
  }
}

.DeviceWrap {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 48px;
}

.ActiveDevice {
  background-color: #ffffff;
}

.BlockRightWrap.Padding {
  padding: 40px;
}

.Block.Small .BlockRightWrap.Padding {
  padding-top: 0;
}

.BlockRightImg {
  max-width: 100%;
  object-fit: contain;
}

.PhoneWrapper {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.BackPhone {
  height: 100%;
  position: absolute;
  left: 50%;
  z-index: 7;
  transform: translateX(-50%);
}

.PhoneImage {
  display: block;
  position: relative;
  top: 10px;
  z-index: 6;
  margin: 0 auto;
  height: 100%;
  max-height: 470px;
  width: 100%;
  max-width: 253px;
  padding: 0 10px;
  object-fit: cover;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.WrapperRightPhone {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 40px 40px 0 40px;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../../public/assets/img/main/gears.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 475px;
  position: relative;
  z-index: 1;
}

@media (max-width: 512px) {
  .WrapperRightPhone {
    background-size: 310px;
  }

  .PhoneImage {
    top: 6px;
    max-width: 161px;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }
}

.ActiveBtn {
  background-color: #ffffff;
  color: #000000;
}

.Block.Small .BlockRightImg {
  height: auto;
}

.ActionListWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.Block.BlockGreen .ActionListWrapper > button {
  background-color: #0c9d46;

  &:hover {
    background-color: #ffffff;

    color: #000000;
  }

  &[data-isactive='true'] {
    background-color: #ffffff;

    &:hover {
      color: #000000;
    }
  }
}

.FullImg {
  height: auto !important;
}

@media (max-width: 1020px) {
  .Block {
    grid-template-columns: 1fr 350px;
  }

  .WrapperRightPhone {
    padding: 60px 60px 0;
    background-size: contain;
  }
}

@media (max-width: 910px) {
  .Block {
    height: 540px;
  }
}

@media (max-width: 820px) {
  .Block {
    grid-template-columns: 1fr;
    max-height: 860px;
    height: 100%;
    max-width: 100%;
  }

  .WrapperRightPhone {
    padding: 28px 0 0;
    margin: 0 28px;
  }

  .ToggleBtnsWrapper {
    right: 50%;
    bottom: 28px;
    transform: translateX(50%);
  }

  .BlockRightImg {
    height: 100% !important;
    max-height: 500px;
    margin: 0 auto;
  }

  .BlockTitle {
    margin-bottom: 8px;
    font-size: 24px;
    letter-spacing: -0.16px;
    line-height: 28px;
  }

  .BlockDesc {
    font-size: 17px;
    letter-spacing: -0.12px;
    line-height: 24px;
  }
}

@media (max-width: 800px) {
  .Block {
    grid-template-columns: 1fr;
  }

  .BlockLeftWrap {
    padding: 28px 28px 0;
  }

  .BlockRightWrap {
    justify-content: center;
  }

  .Subtitle {
    font-size: 24px;
    line-height: 42px;
    letter-spacing: -0.24 px;
    max-width: 75px;
    padding: 0 3px;
  }

  .ActionListWrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .FullImg {
    padding: 0 40px;
  }

  .FullImg {
    padding: 28px 28px 0;
  }
}

@import 'core/design-tokens';

@import 'atoms/title';
@import 'atoms/text-field';
@import 'atoms/cabinet-select';
@import 'atoms/logo';

@import 'molecules/sign-up-form';
@import 'molecules/sign-in-form';
@import 'molecules/reset-from';
@import 'molecules/new-password-form';
@import 'molecules/form-header';
@import 'molecules/form-footer';
@import 'molecules/modal';

@import 'organisms/page';
@import 'organisms/auth';
@import 'organisms/not-found';
@import 'organisms/notify';
@import 'organisms/auth-page';

@import './fonts';

.image-fluid {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

* {
  padding: 0;
  margin: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type='submit'] {
  display: inline-block;
  box-shadow: none;
  background: transparent none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

body {
  background-color: #f7f7f7;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.12px;
}

.swiper-pagination-bullet {
  color: #0e99fd;
}

.slick-slide {
  width: 192px !important;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  white-space: pre-line;
}

.FadeInContainer {
  opacity: 0;
  transform: translateY(100px);
  visibility: hidden;
  transition: transform 0.4s cubic-bezier(0, 0, 0.4, 1),
    opacity 0.4s cubic-bezier(0, 0, 0.4, 1),
    visibility 0.4s cubic-bezier(0, 0, 0.4, 1);
}

.FadeInContainer.Visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.FadeInContainer.Flex {
  display: flex;
}

.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__inner {
    width: 100%;
    max-width: 344px;
    margin: 64px 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__navigate {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 20px;
  }

  &__link {
    text-decoration: none;
    color: var(--blue-70);
  }

  &__privacy {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-quinary);
  }
}

@media (max-width: $phone) {
  .auth {
    &__title {
      margin-bottom: 24px;
    }
  }
}

.Button {
  padding: 8px 16px;
  background-color: #046be0;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  display: block;
  font-family: inherit;
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.08px;
  transition: 0.2s cubic-bezier(0, 0, 0.4, 1);
  width: auto;
}

.Stretch {
  width: 100%;
}

.Button:not(:disabled):hover {
  background-color: #0582ff;
}

.Button.Large {
  padding: 12px 20px;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.04px;
}

.Button.Tool {
  padding: 0px 8px;
  font-size: 12px;
  transform: translateY(-2px);
}

.GreyTheme {
  background: #353538;
  color: #ffffff;
}

.GreyTheme:not(:disabled):hover {
  background-color: #ffffff;
  color: #000000;
}

.DarkTheme {
  background: #212124;
  color: #0582ff;
}

.DarkTheme:not(:disabled):hover {
  background-color: #262626;
}

.Button.IsActive {
  background-color: #ffffff;
  cursor: default;
  color: #000000;
}

.formBtn {
  width: 100%;
  background-color: #3748c6;
  border-radius: 6px;
  height: 40px;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    background-color: #5666de !important;
  }
}

.disabled {
  background-color: #929292;
  color: #ffffff;

  &:hover {
    background-color: #929292 !important;
  }
}

@keyframes open {
  from {
    height: 0;
    padding: 0;
    margin-top: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
    padding-bottom: 20px;
    margin-top: -4px;
  }
}

@keyframes close {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
    padding: 0;
    margin-top: 0;
  }
}

.Item {
  border-bottom: 1px solid #E6E6E6;
}

.Content {
  margin-top: -4px;
  padding-bottom: 20px;
  overflow: hidden;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04px;

  &[data-state="open"] {
    animation: open 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s 1 normal forwards;
  }
  &[data-state="closed"] {
    animation: close 300ms cubic-bezier(0.87, 0, 0.13, 1) 0s 1 normal forwards;
  }

  @media (max-width: 743px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.Content > a {
  color: inherit;
  text-decoration: underline;
}

.Trigger {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  font-family: inherit;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.16px;

  @media (max-width: 743px) {
    padding: 16px 0;
    font-size: 20px;
    line-height: 24px;
  }
}

.TriggerIcon {
  flex-shrink: 0;
  height: 24px;
  margin-left: auto;
  transform: rotate(45deg);
  transition: 0.2s ease-in-out;
}

.Trigger[data-state="open"] .TriggerIcon {
  transform: rotate(0);
}

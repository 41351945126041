@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Inter'), url('../../../../public/assets/fonts/Inter/Regular/InterRegular.woff2') format('woff2'), url('../../../../public/assets/fonts/Inter/Regular/InterRegular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Inter'), url('../../../../public/assets/fonts/Inter/Medium/InterMedium.woff2') format('woff2'), url('../../../../public/assets/fonts/Inter/Medium/InterMedium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Inter'), url('../../../../public/assets/fonts/Inter/SemiBold/InterSemiBold.woff2') format('woff2'), url('../../../../public/assets/fonts/Inter/SemiBold/InterSemiBold.woff') format('woff');
}

.wrapper {
  position: relative;
}

.title {
  margin-left: 8px;
}

.current {
  &-language {
    display: inline-flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #808080;
    cursor: pointer;

    &:hover {
      color: #a6a6a6;
    }
  }

  &-language-item {
    margin-top: 2px;
    padding: 6px;
    color: #afafb3;

    &:hover {
      background: #353538;
      border-radius: 8px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.language-list-wrapper {
  display: none;
  flex-direction: column;
  position: absolute;
  top: -95px;
  left: -14px;
  padding: 6px;
  background: #212124;
  border: 1px solid #353538;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  min-width: 120px;
}

.flag {
  width: 20px;
  height: 20px;
}

.open {
  display: flex;
  animation: fadeIn 0.4s;
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__body {
    width: 100%;
    max-width: 344px;
    margin: 64px 20px;
  }

  .text-field {
    margin-bottom: 20px;
  }
}

.sign-in-form {
  &__text-field {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__remember-me {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    line-height: 1;
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
    text-decoration: none;
  }
}

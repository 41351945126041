.form-header {
  text-align: center;
  margin-bottom: 40px;
  white-space: pre-line;

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #040134;

    &:not(&:last-child) {
      margin-bottom: 12px;
    }
  }

  &__sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6a6a7a;
  }
}

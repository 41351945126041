.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.568);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000010;
  padding: 20px;
}

.modal {
  max-width: 320px;
  padding: 24px;
  border-radius: 32px;
  background: #ffffff;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.text {
  margin-top: 12px;
  font-size: 13px;
  line-height: 20px;
}

.link {
  color: #3748c6;
}

.buttons {
  margin-top: 24px;
  display: flex;
  gap: 15px;
}

.button {
  flex: 1;
  background-color: #046be0;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 13px;
  border-radius: 24px;
  letter-spacing: 0.04px;
  transition: all 0.3s;

  &:hover {
    background-color: #0582ff;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.close {
  position: relative;
  top: -2px;
  left: -1px;
  transition: all 0.3s;

  &:hover {
    color: rgb(85, 85, 85);
  }
}
